import React, { useState } from "react";
import Button from "@mui/material/Button";
import "../style/ButtonDownloadLogo.css";
import Cloud from "../img/telecharger.svg";
import dupliquer from "../img/dupliquer.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { svg2png } from "svg-png-converter";

export default function ButtonDownload(props) {
  const [data, setData] = useState([]);

  svg2png({
    input: `${props.svg}`.trim(),
    encoding: "dataURL",
    format: "png",
  }).then((result) => {
    setData(result);
  });

  return (
    <div>
      {props.typedl === "png" && (
        <Button class="buttondownload">
          <div className="ButtonDownloadDisplay">
            <a href={data} download={props.nom} className="download">
              <img src={Cloud} className="Landscape" alt="Landscape" />
              <span className="downloadtextbutton">Télécharger</span>
            </a>
          </div>
        </Button>
      )}
      {props.typedl === "svg" && (
        <Button class="buttondownload">
          <div className="ButtonDownloadDisplay">
            <a
              href={`data:image/svg+xml;base64,${btoa(props.svg)}`}
              download={props.nom}
              className="download"
            >
              <img src={Cloud} className="Landscape" alt="Landscape" />
              <span className="downloadtextbutton">Télécharger</span>
            </a>
          </div>
        </Button>
      )}
      {props.typedl === "lien" && (
        <CopyToClipboard
          text={`${
            process.env.REACT_APP_API_URL.includes("http")
              ? process.env.REACT_APP_API_URL
              : `${window.location.origin}${process.env.REACT_APP_API_URL}`
          }/logolink/${props.idpicto}?taille=${props.size}`}
        >
          <Button class="buttondownload">
            <div className="ButtonDownloadDisplay">
              <img src={dupliquer} className="Landscape" alt="dupliquer" />
              <span className="downloadtextbutton2">Copier le lien</span>
            </div>
          </Button>
        </CopyToClipboard>
      )}
    </div>
  );
}
