import React from "react";
import "../style/ColorPicker.css";

const colors = [
  "vert",
  "jaunefonce",
  "jauneclair",
  "bleuclair",
  "bleufonce",
  "saumon",
  "saumonclair",
  "vertforet",
  "grisclair",
];

const ColorPicker = ({ color, onChange }) => {
  return (
    <div className="colorpickerdisplay">
      {colors.map((c) => (
        <button className={`color ${c}`} onClick={() => onChange(c)}>
          {c === color && <div className="Oval" />}
        </button>
      ))}
    </div>
  );
};
export default ColorPicker;
