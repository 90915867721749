import React from "react";
import "../style/Filtre.css";
import SearchBar from "../component/SearchBar";
import MultipleSelect from "../component/Selector";
import CustomizedSwitches from "../component/Switches";

const Filtre = (props) => {
  return (
    <div className="box">
      <SearchBar setSearch={props.setSearch} setToggle={props.setToggle} />
      <MultipleSelect setCategorie={props.setCategorie} />
      <CustomizedSwitches
        triggerToggle={props.triggerToggle}
        toggle={props.toggle}
      />
    </div>
  );
};
export default Filtre;
