import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import "../style/Popover.css";
import points from "../img/trois-points.png";
import closebnt from "../img/Fermer.svg";
import DownloadType from "./DownloadType";
import SizeBar from "./Size";
import ColorPicker from "./ColorPicker";
import ColorPicker2 from "./ColorPicker2";
import AddCircle from "./AddCircle";
import ShiftCircle from "./ShiftCircle";
import ButtonDownload from "./ButtonDownload";

export default function PopoverPicto(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [couleurpicto, setCouleurpicto] = useState(undefined);
  const [couleurfond, setCouleurfond] = useState(undefined);
  const [typedl, settypedl] = useState("png");
  let FontRectangle;
  const [hasBackground, setHasBackground] = useState(false);
  const [isBgOffset, setIsBgOffset] = useState(false);
  const [size, setSize] = useState("270");
  let urlPicto = `${process.env.REACT_APP_API_URL}/link/${props.picto.id}/${props.type}?`;
  if (size) {
    urlPicto += `taille=${size}&`;
  }
  if (couleurpicto) {
    urlPicto += `couleurpicto=${couleurpicto}&`;
  }
  if (couleurfond) {
    urlPicto += `couleurfond=${couleurfond}&`;
  }
  if (hasBackground) {
    let background = "centre";
    if (isBgOffset) {
      background = "decale";
    }
    urlPicto += `rond=${background}`;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHasBackground(false);
  };

  if (props.toggle) {
    FontRectangle = "Rectangleimgpopover";
  } else {
    FontRectangle = "Rectangleimgpopover2";
  }

  return (
    <div>
      <button className="buttonpop" aria-describedby={id} onClick={handleClick}>
        <img src={points} className="Trois-points" alt="troispetitspoints" />
      </button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <button onClick={handleClose} className="closepop">
          <img src={closebnt} alt="close" />
        </button>
        <div className="Rectanglepopover">
          <div className="divpopover">
            <div>
              <h1 className="Some-text-fichier">Format de fichier</h1>
              <DownloadType settypedl={settypedl} />
              <h1 className="Some-text-fichier">Largeur de l'icône</h1>
              <SizeBar triggerSize={(event) => setSize(event.target.value)} />
              {props.toggle === true && (
                <div>
                  <h1 className="Some-text-fichier">Couleur du picto</h1>
                  <ColorPicker
                    color={couleurpicto}
                    onChange={setCouleurpicto}
                  />
                  <h1 className="Some-text-fichier">Fond</h1>
                  <AddCircle
                    triggerHasBackground={(event) =>
                      setHasBackground(event.target.checked)
                    }
                  />
                </div>
              )}
              {hasBackground === true && (
                <div>
                  <h1 className="Some-text-fichier">Couleur du fond</h1>
                  <div className="DisplayShiftCircle">
                    <ColorPicker2
                      color={couleurfond}
                      onChange={setCouleurfond}
                    />
                    <div className="shiftcircledisplay">
                      <ShiftCircle
                        triggerToggle3={(event) =>
                          setIsBgOffset(event.target.checked)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={FontRectangle}>
              <div className="Rectangleimgpopoverdiv">
                <img
                  id="image"
                  src={urlPicto}
                  alt="picto"
                  className="centerimg"
                />
              </div>
              <div>
                <div className="popoverdisplaynamepicto">
                  <span
                    className={
                      props.toggle ? "popovernamepicto" : "popovernamepicto2"
                    }
                  >
                    {props.picto.nom}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="buttondownloadcoor">
            <ButtonDownload
              typedl={typedl}
              nom={props.picto.nom}
              url={`${
                urlPicto.includes("http")
                  ? urlPicto
                  : `${window.location.origin}${urlPicto}`
              }`}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}
