import React, { useState, useEffect } from "react";
import Entete2 from "../component/Entete2";
import "../style/LogoPage.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { svg2png } from "svg-png-converter";
import PopoverLogo from "../component/PopoverLogo";

export default function Logo() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/logo`)
      .then((res) => setData(res.data));
  }, []);
  function downloadBase64File(nomsvg, result) {
    const linkSource = `${result}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = nomsvg;
    downloadLink.click();
  }
  const displaypicto = (svgtopng, nomsvg) => {
    svg2png({
      input: `${svgtopng}`.trim(),
      encoding: "dataURL",
      format: "png",
    }).then((result) => {
      downloadBase64File(nomsvg, result);
    });
  };

  return (
    <div>
      <div>
        <h1 className="Logos-Easylife">Logos Easylife</h1>
      </div>
      <div className="wrapperLogo">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          {data.map((pictos, idx) => (
            <div key={idx}>
              {pictos.couleur === "blanc" && (
                <div className="RectanglePicto2Logo">
                  <PopoverLogo
                    colorback="black"
                    imgpicto={pictos.logoimg}
                    nompicto={pictos.nom}
                    idpicto={pictos.id}
                  />
                  <div onClick={() => displaypicto(pictos.logoimg, pictos.nom)}>
                    <div className="divlogo3">
                      <img
                        className="imglogo3"
                        id="image"
                        src={`${process.env.REACT_APP_API_URL}/logolink/${pictos.id}`}
                        alt="picto"
                      />
                    </div>
                    <span
                      className="PictoList2Logo"
                      data-toggle="tooltip"
                      data-placement="left"
                      title={pictos.nom}
                    >
                      {pictos.nom}
                    </span>
                  </div>
                </div>
              )}
              {pictos.couleur === "couleur" && (
                <div className="RectanglePictoLogo">
                  <PopoverLogo
                    colorback="white"
                    imgpicto={pictos.logoimg}
                    nompicto={pictos.nom}
                    idpicto={pictos.id}
                  />
                  <div onClick={() => displaypicto(pictos.logoimg, pictos.nom)}>
                    <div className="divlogo3">
                      <img
                        className="imglogo3"
                        id="image"
                        src={`${process.env.REACT_APP_API_URL}/logolink/${pictos.id}`}
                        alt="picto"
                      />
                    </div>
                    <span
                      className="PictoListLogo"
                      data-toggle="tooltip"
                      data-placement="left"
                      title={pictos.nom}
                    >
                      {pictos.nom}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Grid>
      </div>
      <Entete2 />
    </div>
  );
}
