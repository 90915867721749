import React from "react";
import "../style/Entete.css";
import pictoimg1 from "../img/les-pictos.png";
import { useHistory } from "react-router-dom";

const Entete = () => {
  let history = useHistory();

  const redirectlogo = () => {
    history.push("/logo");
  };

  const redirecthome = () => {
    history.push("/");
  };

  return (
    <div className="menu1">
      <div className="menu2">
        <button className="buttonentete" onClick={redirecthome}>
          <img className="imgheader" src={pictoimg1} alt="Les Pictos"></img>
        </button>
        <div className="divlogo">
          <button className="buttonentete" onClick={redirectlogo}>
            <h1 className="logoActive">LOGOS</h1>
          </button>
        </div>
        <div className="divcharte">
          <a href={process.env.PUBLIC_URL + "/Charte graphique.pdf"} download>
            <button className="buttonentete">
              <h1 className="charteGraphique">CHARTE GRAPHIQUE</h1>
            </button>
          </a>
        </div>
      </div>
      <div className="Rectangle"></div>
    </div>
  );
};

export default Entete;
