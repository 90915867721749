import "../style/Size.css";
import React from "react";

const SizeBar = (props) => {
  return (
    <div className="sizedisplay">
      <input
        className="Rectangle-Copy222"
        defaultValue="270"
        onChange={props.triggerSize}
      />
      <h1 className="textpixel">pixels</h1>
    </div>
  );
};
export default SizeBar;
