import React from "react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../style/DownloadType.css";

const AntToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#52c483",
    color: "white",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#52c483",
  },
  "&.MuiToggleButton-root": {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
  },
}));

export default function ToggleButtons(props) {
  const [alignment, setAlignment] = React.useState("left");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      className="typedownload"
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      color="primary"
    >
      <AntToggleButton
        value="left"
        aria-label="left aligned"
        onClick={(event) => props.settypedl("png")}
      >
        PNG
      </AntToggleButton>
      <AntToggleButton
        value="center"
        aria-label="centered"
        onClick={(event) => props.settypedl("svg")}
      >
        SVG
      </AntToggleButton>
      <AntToggleButton
        value="right"
        aria-label="right aligned"
        onClick={(event) => props.settypedl("lien")}
      >
        Lien
      </AntToggleButton>
    </ToggleButtonGroup>
  );
}
