import React, { useState, useEffect } from "react";
import "../style/Selector.css";
import { styled } from "@mui/material/styles";
import axios from "axios";
import DropdownImg from "./Dropdownimg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const AntSelect = styled(Select)(({ theme }) => ({
  width: "234px",
  height: "40px",
  padding: "0",
  borderRadius: "8px",
  display: "flex",

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },
}));

export default function MultipleSelect(props) {
  const [data, setData] = useState([]);

  const [categorie, setCategorie] = React.useState("");
  const handleChange = (event) => {
    setCategorie(event.target.value);
    props.setCategorie(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pictoCategories`)
      .then((res) => setData(res.data));
  }, []);

  return (
    <div className="mobilesize">
      <FormControl fullWidth>
        <span className="Some-text2">Catégorie</span>
        <AntSelect
          inputProps={{ "aria-label": "ant design" }}
          IconComponent={() => <DropdownImg className="Dropdown" />}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={categorie}
          onChange={handleChange}
          className="Rectangle-Copy2"
        >
          <MenuItem value="undefined">Tous</MenuItem>
          {data.map((categorie, idx) => (
            <MenuItem key={idx} value={categorie.id}>
              {categorie.nom}
            </MenuItem>
          ))}
        </AntSelect>
      </FormControl>
    </div>
  );
}
