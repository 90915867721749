import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Bienvenue from "../pages/Bienvenue";
import Logo from "../pages/Logo";

export default function RoutePicto() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/logo">
            <Logo />
          </Route>
          <Route path="/">
            <Bienvenue />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
