import React, { useState, useEffect } from "react";
import "../style/Cartesvg.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import PopoverPicto from "./Popover";
import { svg2png } from "svg-png-converter";

const Cartesvg = (props) => {
  const [data, setData] = useState([]);
  const urlPicto = (id, type) =>
    `${process.env.REACT_APP_API_URL}/link/${id}/${type}`;
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchPictos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pictos`,
          {
            params: {
              search: props.search,
              categoryId: props.categorie,
            },
            cancelToken: source.token,
          }
        );
        setData(response.data || []);
      } catch (err) {
        console.log("Previous request was canceled");
      }
    };
    fetchPictos();
    return () => source.cancel();
  }, [props.search, props.categorie]);

  function downloadBase64File(nomsvg, result) {
    const downloadLink = document.createElement("a");
    downloadLink.href = result;
    downloadLink.download = nomsvg;
    downloadLink.click();
  }
  const downloadToPng = (svgtopng, nomsvg) => {
    svg2png({
      input: `${svgtopng}`.trim(),
      encoding: "dataURL",
      format: "png",
    }).then((result) => {
      downloadBase64File(nomsvg, result);
    });
  };

  return (
    <div className="divmobile">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        {data.map((pictos, idx) => {
          let classNameRectangle = "RectanglePicto";
          let classNameList = "PictoList";
          let image = pictos.imageCouleur;
          let type = "couleur";
          if (!props.toggle) {
            classNameRectangle = "RectanglePicto2";
            classNameList = "PictoList2";
            image = pictos.imageBlanc;
            type = "blanc";
          }
          return (
            <div key={idx}>
              <div className={classNameRectangle}>
                <PopoverPicto
                  picto={pictos}
                  type={type}
                  toggle={props.toggle}
                />
                <div onClick={() => downloadToPng(image, pictos.nom)}>
                  <img
                    style={{ width: "5rem", height: "5rem" }}
                    id="image"
                    src={urlPicto(pictos.id, type)}
                    alt="picto"
                  />
                </div>
                <span
                  className={classNameList}
                  data-toggle="tooltip"
                  data-placement="left"
                  title={pictos.nom}
                >
                  {pictos.nom}
                </span>
              </div>
            </div>
          );
        })}
      </Grid>
    </div>
  );
};

export default Cartesvg;
