import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../style/ButtonDownload.css";
import Cloud from "../img/telecharger.svg";
import dupliquer from "../img/dupliquer.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { svg2png } from "svg-png-converter";
import axios from "axios";

export default function ButtonDownload({ typedl, nom, url }) {
  const [dataPng, setDataPng] = useState([]);
  const [dataSvg, setDataSvg] = useState([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchPicto = async () => {
      try {
        const response = await axios.get(url, {
          cancelToken: source.token,
        });
        setDataSvg(response.data);
        console.log(response.data);
        const dataPng = await svg2png({
          input: `${response.data}`.trim(),
          encoding: "dataURL",
          format: "png",
        });
        setDataPng(dataPng);
      } catch (err) {
        console.log("Previous request was canceled");
      }
    };
    fetchPicto();
    return () => source.cancel();
  }, [url]);

  return (
    <div>
      {typedl === "png" && (
        <Button class="buttondownload">
          <div className="ButtonDownloadDisplay">
            <a href={dataPng} download={nom} className="download">
              <img src={Cloud} className="Landscape" alt="Landscape" />
              <span className="downloadtextbutton">Télécharger</span>
            </a>
          </div>
        </Button>
      )}
      {typedl === "svg" && (
        <Button class="buttondownload">
          <div className="ButtonDownloadDisplay">
            <a
              href={`data:image/svg+xml;base64,${new Buffer(dataSvg).toString(
                "base64"
              )}`}
              download={nom}
              className="download"
            >
              <img src={Cloud} className="Landscape" alt="Landscape" />
              <span className="downloadtextbutton">Télécharger</span>
            </a>
          </div>
        </Button>
      )}
      {typedl === "lien" && (
        <CopyToClipboard text={url}>
          <Button class="buttondownload">
            <div className="ButtonDownloadDisplay">
              <img src={dupliquer} className="Landscape" alt="dupliquer" />
              <span className="downloadtextbutton2">Copier le lien</span>
            </div>
          </Button>
        </CopyToClipboard>
      )}
    </div>
  );
}
