import React from "react";
import "../style/ColorPicker.css";

const colors = ["grisclair", "blanc"];

const ColorPicker2 = ({ color, onChange }) => {
  return (
    <div className="colorpickerdisplay">
      {colors.map((c) => (
        <button className={`color ${c}`} onClick={() => onChange(c)}>
          {c === color && <div className={c === "blanc" ? "Oval2" : "Oval"} />}
        </button>
      ))}
    </div>
  );
};
export default ColorPicker2;
