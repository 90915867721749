import "../style/SearchBar.css";
import loupe from "../img/loupe.png";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));
const SearchBar = (props) => {
  const classes = useStyles();
  return (
    <div className="searchsbar">
      <TextField
        variant="outlined"
        margin="normal"
        required
        autoFocus
        onChange={(event) => props.setSearch(event.target.value)}
        className="Rectangle-Copy"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={loupe} className="Loupe" alt="Search" />
            </InputAdornment>
          ),
          classes: { notchedOutline: classes.noBorder },
        }}
      />
    </div>
  );
};
export default SearchBar;
