import React, { useState } from "react";
import "../style/BienvenuePage.css";
import HomeText from "../component/Hometext";
import Entete from "../component/Entete";
import Cartesvg from "../component/Cartesvg";
import Filtre from "../component/Filtre";

const Bienvenue = (props) => {
  const [toggle, setToggle] = useState(true);

  const [search, setSearch] = useState();
  const [categorie, setCategorie] = useState(undefined);
  return (
    <div>
      <div className="wrapper">
        <HomeText />
        <Filtre
          triggerToggle={() => setToggle(!toggle)}
          toggle={toggle}
          setSearch={setSearch}
          setCategorie={setCategorie}
        />
        <Cartesvg toggle={toggle} search={search} categorie={categorie} />
      </div>
      <Entete />
    </div>
  );
};

export default Bienvenue;
