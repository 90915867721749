import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import "../style/PopoverLogo.css";
import "../style/Cartesvg.css";
import points from "../img/trois-points.png";
import DownloadType from "./DownloadType";
import SizeBar from "./Size";
import ButtonDownloadLogo from "./ButtonDownloadLogo";
import closepop from "../img/Fermer.svg";
export default function PopoverPicto(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setp(props.imgpicto);
  }, [props.imgpicto]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setp(props.imgpicto);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [size, setSize] = useState("270");
  const [typedl, settypedl] = useState("png");
  var FontRectangle = "Rectangleimgpopover";
  var FontName = "popovernamepicto";
  if (props.colorback === "white") {
    FontRectangle = "Rectangleimgpopover";
  } else {
    FontRectangle = "Rectangleimgpopover2";
  }
  if (props.colorback === "white") {
    FontName = "popovernamepicto";
  } else {
    FontName = "popovernamepicto2";
  }

  const [p, setp] = useState(props.imgpicto);
  const triggerSize = (currentValue) => {
    setSize(currentValue);
    setp(
      p.replace(
        /<svg width=".{0,5}" height=".{0,5}"/m,
        `<svg width="${currentValue}" height="${currentValue}"`
      )
    );
  };

  return (
    <div>
      <button className="buttonpop" aria-describedby={id} onClick={handleClick}>
        <img src={points} className="Trois-points" alt="troispetitspoints" />
      </button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <button onClick={handleClose} className="closepop2">
          <img src={closepop} alt="closepop" />
        </button>
        <div className="Rectanglepopover">
          <div className="divpopover">
            <div>
              <h1 className="Some-text-fichier">Format de fichier</h1>
              <DownloadType
                svg={props.imgpicto}
                nom={props.nompicto}
                settypedl={settypedl}
              />
              <h1 className="Some-text-fichier">Largeur de l'icône</h1>
              <SizeBar
                triggerSize={(event) => triggerSize(event.target.value)}
              />
            </div>
            <div className={FontRectangle}>
              <div className="Rectangleimgpopoverdiv">
                <img
                  id="image"
                  src={`${process.env.REACT_APP_API_URL}/logolink/${props.idpicto}`}
                  alt="picto"
                  className="centerimg"
                />
              </div>
              <div>
                <div className="popoverdisplaynamepicto">
                  <span className={FontName}>{props.nompicto}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="buttondownloadcoor">
            <ButtonDownloadLogo
              typedl={typedl}
              svg={p}
              size={size}
              nom={props.nompicto}
              idpicto={props.idpicto}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}
