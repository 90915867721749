import React from "react";
import "../style/Hometext.css";

const HomeText = () => {
  return (
    <div>
      <h1 className="Bienvenue">Bienvenue</h1>
      <h1 className="Te-voici-dans-la-bas">
        Te voici dans la base de données des pictos, qui contient l’intégralité
        des
        <br />
        pictos réalisés pour la communication d’Easylife.
        <br />
      </h1>
      <h1 className="Te-voici-dans-la-bas">
        Tu peux les téléchager tout simplement, ou si tu le souhaite, en
        cliquant sur les « … »,
        <br />
        tu peux obtenir une taille différente de l’image et … en changer la{" "}
        <span className="text-style-1">c</span>
        <span className="text-style-2">o</span>
        <span className="text-style-3">u</span>
        <span className="text-style-4">l</span>
        <span className="text-style-5">e</span>
        <span className="text-style-6">u</span>
        <span className="text-style-7">r</span>!
      </h1>
    </div>
  );
};

export default HomeText;
