import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import "../style/ShiftCircle.css";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: "46px",
  height: "20px",
  padding: "0",
  borderRadius: "10px",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#52c483",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function ShiftCircle(props) {
  return (
    <div className="addcircledisplay0">
      <FormGroup className="switchformgroupe-addcircle0">
        <Stack direction="row" spacing={1} alignItems="center">
          <AntSwitch
            inputProps={{ "aria-label": "ant design" }}
            onChange={props.triggerToggle3}
          />
        </Stack>
      </FormGroup>
      <h1 className="Valeur-dsactive-addcircle0">Décaler le rond</h1>
    </div>
  );
}
